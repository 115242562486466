import React from 'react';
import { Box, TagList, HR } from '@parsleyhealth/cilantro-ui';
import useSymptomTags from '../../hooks/use-symptom-tags';
import BlogSectionTitle from './blog-section-title';

const BlogSymptomsList = () => {
  const symptoms = useSymptomTags();
  return (
    <Box>
      <BlogSectionTitle variant="heading2" color="textGray">
        Browse by Symptom
      </BlogSectionTitle>
      <HR />
      <TagList list={symptoms} color="textBlue" />
    </Box>
  );
};

export default BlogSymptomsList;

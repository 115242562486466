import React from 'react';
import styled from '@emotion/styled';
import { Box, Mq, theme, H2, Text, Button } from '@parsleyhealth/cilantro-ui';
import SanityImage from '../NewSanityImage';
import { getGuideUrl } from '../../lib/blog-utils';
import { SanityBasicImage } from 'graphql-types';

interface BlogGuideItemProps {
  color: string;
  description: string;
  href: string;
  prefix?: string;
  image: SanityBasicImage;
  invertLayout?: boolean;
  title: string;
}

const Container = styled(Box)`
  flex-direction: row;
  display: flex;
  ${Mq(2)} {
    flex-direction: row;
  }
`;

const ImageContainer = styled(Box)`
  width: 100%;
  height: auto;
  max-height: 300px;
  ${Mq(2)} {
    width: 65%;
    max-height: 700px;
  }
  ${Mq(4)} {
    width: 65%;
    max-height: 460px;
  }
`;

const SeparateElements = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const ContainerText = styled(({ invertLayout, ...rest }) => <Box {...rest} />)<{
  invertLayout?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${theme.space.l}px;
  ${Mq(2)} {
    width: 35%;
    order: ${props => (props.invertLayout ? -1 : 1)};
  }
`;

const BlogGuideItem = ({
  color,
  description,
  href,
  prefix,
  image,
  invertLayout = false,
  title
}: BlogGuideItemProps) => {
  return (
    <Container mb="xl" flexWrap="wrap">
      <ImageContainer>
        <SanityImage
          image={image}
          imageSizes={['100vw', '60vw']}
          coverContainer
        />
      </ImageContainer>
      <ContainerText backgroundColor={color} invertLayout={invertLayout}>
        <H2 color="white">{title}</H2>
        <SeparateElements>
          <Text variant="pRegular" color="white" mb="l">
            {description}
          </Text>
          <Button
            href={getGuideUrl(href, prefix)}
            variant="fullWhite"
            aria-label="Learn More"
          >
            Learn More
          </Button>
        </SeparateElements>
      </ContainerText>
    </Container>
  );
};

export default BlogGuideItem;

import React from 'react';
import { graphql } from 'gatsby';
import { SanityBlogGuide } from 'graphql-types';
import { useBlogConfig } from '../../hooks/use-blog-config';

import { WebsiteWrapper, H1, Box } from '@parsleyhealth/cilantro-ui';
import BlogLayout from '../../components/Blog/blog-layout';
import BlogGuideItem from '../../components/Blog/blog-guide-item';
import BlogSymptomsList from '../../components/Blog/BlogSymptomsList';
import { default as SEO } from '../../components/SEO';

interface BlogGuidesPageProps {
  data: {
    allSanityBlogGuide: {
      edges: {
        node: SanityBlogGuide;
      }[];
    };
  };
}

const BlogGuidesPage = ({ data }: BlogGuidesPageProps) => {
  const { metaDataGuide } = useBlogConfig();
  const guides = data?.allSanityBlogGuide?.edges;

  return (
    <>
      <SEO
        pageTitle={metaDataGuide.metaTitle}
        pageDescription={metaDataGuide.metaDescription}
        pageImage={metaDataGuide.metaImage}
        noindex={metaDataGuide.noindex}
      />
      <BlogLayout paddingTop={false}>
        <Box py="xl" mb="xl" backgroundColor="backgroundDarkBlue">
          <H1 textAlign="center" color="white" mb="xl">
            Parsley Health Guides
          </H1>
          <WebsiteWrapper>
            {guides.map(({ node: guide }, i) => (
              <BlogGuideItem
                key={`guide-${i}`}
                image={guide?.image}
                title={guide?.title}
                description={guide.heroDescription[0]?.children[0].text}
                color={guide?.color?.hex}
                href={guide?.slug?.current}
                invertLayout={Boolean(i % 2)}
              />
            ))}
          </WebsiteWrapper>
        </Box>
        <WebsiteWrapper>
          <BlogSymptomsList />
        </WebsiteWrapper>
      </BlogLayout>
    </>
  );
};

export const query = graphql`
  query BlogGuidesQuery {
    allSanityBlogGuide {
      edges {
        node {
          title
          slug {
            current
          }
          color {
            hex
          }
          heroDescription {
            children {
              _type
              text
            }
          }
          image {
            _type
            ...SanityImageFragment
          }
        }
      }
    }
  }
`;

export default BlogGuidesPage;

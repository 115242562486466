import { useStaticQuery, graphql } from 'gatsby';
import { SanityCondition } from '../../graphql-types';
import { getConditionUrl } from '../lib/blog-utils';

interface SymptomCategory {
  title: string;
  items: {
    label: string;
    destination: string;
  }[];
}

const useSymptomTags = () => {
  const { allSanityCondition } = useStaticQuery(
    graphql`
      query getSymptomTags {
        allSanityCondition(filter: { slug: { current: { ne: null } } }) {
          nodes {
            slug {
              current
            }
            title
            category {
              id
              title
            }
          }
        }
      }
    `
  );

  const symptoms = allSanityCondition.nodes
    .filter((i: SanityCondition) => i.category && i.category.length)
    .reduce((acc: SymptomCategory[], item: SanityCondition) => {
      const category = item.category[0].title;
      const categoryIndex = acc.findIndex(i => i.title === category);
      const tag = {
        label: item.title,
        destination: getConditionUrl(item.slug.current)
      };

      if (categoryIndex !== -1) {
        acc[categoryIndex].items.push(tag);
      } else {
        acc.push({
          title: category,
          items: [tag]
        });
      }

      return acc;
    }, []);

  return symptoms;
};

export default useSymptomTags;

import React from 'react';
import { Text } from '@parsleyhealth/cilantro-ui';

const BlogSectionTitle = ({ children, ...rest }) => {
  return (
    <Text variant="heading2" color="textGray" {...rest}>
      {children}
    </Text>
  );
};

export default BlogSectionTitle;
